import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { PriceSection, PricingTable, PricingBadge, PricingFeatureList, PricingFeatureItem, PricingButton, PricingFeatureIconWrapper, StyledButton1, StyledButton3 } from '../components/styledComponents';
import symbolSvg from '../assets/img/symbol.svg';
import symbol1Svg from '../assets/img/symbol1.svg';
import symbol3Svg from '../assets/img/symbol3.svg';
import EllipseSvg from '../assets/img/Ellipse_small.svg';
import StrokeSvg from '../assets/img/stroke.svg';
import '@fortawesome/fontawesome-free/css/all.min.css';
import baseURL from '../baseURL/baseURL';
import Cookies from 'js-cookie';

const PricingPage = () => {
  const [packages, setPackages] = useState([]);
  useEffect(() => {
		fetch(`${baseURL}/allPackages`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setPackages(data.packages);
			})
			.catch((error) => console.error('Error fetching packages:', error));
	}, []);

  const handleClick = () => {
    window.location.href = "https://ksolsofttech.com/sparepart360/auth-pages/get-started#";
  }

  const handlePurchase = () => {
    window.location.href = "https://ksolsofttech.com/sparepart360/auth-pages/get-started";
  }
  

  const handlePackage = (id) => {
    Cookies.set('packageId1', id, { path: '/', domain: 'ksolsofttech.com' });
    Cookies.set('newUser1', 1, { path: '/', domain: 'ksolsofttech.com' });
    window.location.href = "https://ksolsofttech.com/sparepart360/auth-pages/get-started#";
  };
  return (
    <>
 <PriceSection id="pricing">
  <Container sx={{ marginTop: '100px' }}>
    <Typography variant="h6" color="red" fontWeight={400} align="center" gutterBottom>
      Our Pricing
    </Typography>
    <Typography variant="h3" fontWeight={700} align="center" marginBottom={10}>
      Transparent Pricing Plans, Find the <br /> Perfect Fit for Your Needs
    </Typography>

    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} lg={4}>
        <PricingTable>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '35px', gap: '15px' }}>
            <Box component="img" src={symbolSvg} alt="Basic Symbol" sx={{ maxWidth: '100%', height: '60px' }} />
            <Box>
              <Typography variant="h5" fontWeight={700} marginBottom="4px" lineHeight="1.2rem">Basic</Typography>
              <Typography>For small business</Typography>
            </Box>
          </Box>
          <hr />
          <Typography variant="h4" marginTop={2} marginBottom={2}>
          PKR {packages && packages[1] && !isNaN(parseFloat(packages[1]?.price)) ? parseFloat(packages[1]?.price)?.toFixed(0) : 5000 } <small style={{ fontSize: '16px', lineHeight: '1.6rem', color: '#6b6b6b' }}> / month</small>
          </Typography>

          <PricingFeatureList>
          <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Real-time data insights on your dashboard</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Efficient parts management and tracking</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Streamlined inventory operations with optimal stock levels</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Boost sales performance with customer satisfaction tools</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Manage product transfers seamlessly</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Keep store operations organized and efficient</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Handle financial accounts effortlessly</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Create and track vouchers accurately</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Only one month data is shown</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Only three salesman can be created</PricingFeatureItem>
          </PricingFeatureList>
          <PricingButton  onClick={()=>{handlePackage(2)}}>Choose Plan</PricingButton>
        </PricingTable>
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <PricingTable sx={{ backgroundColor: 'red', color: 'white' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '35px', gap: '15px' }}>
            <Box component="img" src={symbol1Svg} alt="Silver Symbol" sx={{ maxWidth: '100%', height: '60px' }} />
            <Box>
              <Typography variant="h5" fontWeight={700} marginBottom="4px" lineHeight="1.2rem">Silver</Typography>
              <Typography>For professionals</Typography>
            </Box>
          </Box>
          <hr />
          <Typography variant="h4" marginTop={2} marginBottom={2}>
          PKR {packages && packages[2] && !isNaN(parseFloat(packages[2]?.price)) ? parseFloat(packages[2]?.price)?.toFixed(0) : 8000 } <small style={{ fontSize: '16px', lineHeight: '1.6rem', color: 'white' }}> / month</small>
          </Typography>

          <PricingFeatureList>
            <PricingFeatureItem><PricingFeatureIconWrapper sx={{ backgroundColor: 'white', color: 'black' }}><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Real-time data insights on your dashboard</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper sx={{ backgroundColor: 'white', color: 'black' }}><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Efficient parts management and tracking</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper sx={{ backgroundColor: 'white', color: 'black' }}><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Streamlined inventory operations with optimal stock levels</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper sx={{ backgroundColor: 'white', color: 'black' }}><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Boost sales performance with customer satisfaction tools</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper sx={{ backgroundColor: 'white', color: 'black' }}><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Manage product transfers seamlessly</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper sx={{ backgroundColor: 'white', color: 'black' }}><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Keep store operations organized and efficient</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper sx={{ backgroundColor: 'white', color: 'black' }}><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper>  Handle financial accounts effortlessly</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper sx={{ backgroundColor: 'white', color: 'black' }}><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Create and track vouchers accurately</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper sx={{ backgroundColor: 'white', color: 'black' }}><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Three months of data is shown</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper sx={{ backgroundColor: 'white', color: 'black' }}><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Six salesman can be created</PricingFeatureItem>
          </PricingFeatureList>
          <PricingBadge>Most Popular</PricingBadge>
          <PricingButton sx={{ backgroundColor: 'white', color: 'black' }} onClick={()=>{handlePackage(3)}}>Choose Plan</PricingButton>
        </PricingTable>
      </Grid>

      {/* Gold Plan */}
      <Grid item xs={12} sm={6} lg={4}>
        <PricingTable>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '35px', gap: '15px' }}>
            <Box component="img" src={symbol3Svg} alt="Gold Symbol" sx={{ maxWidth: '100%', height: '60px' }} />
            <Box>
              <Typography variant="h5" fontWeight={700} marginBottom="4px" lineHeight="1.2rem">Gold</Typography>
              <Typography>For enterprise level</Typography>
            </Box>
          </Box>
          <hr />
          <Typography variant="h4" marginTop={2} marginBottom={2}>
          PKR {packages && packages[3] && !isNaN(parseFloat(packages[3]?.price)) ? parseFloat(packages[3]?.price)?.toFixed(0) : 10000 } <small style={{ fontSize: '16px', lineHeight: '1.6rem', color: '#6b6b6b' }}> / month</small>
          </Typography>

          <PricingFeatureList>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Real-time data insights on your dashboard</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Efficient parts management and tracking</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Streamlined inventory operations with optimal stock levels</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Boost sales performance with customer satisfaction tools</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Manage product transfers seamlessly</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Keep store operations organized and efficient</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Handle financial accounts effortlessly</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Create and track vouchers accurately</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> One year of data is shown</PricingFeatureItem>
            <PricingFeatureItem><PricingFeatureIconWrapper><i className="fa-solid fa-check"></i></PricingFeatureIconWrapper> Ten salesman can be created</PricingFeatureItem>
          </PricingFeatureList>
          <PricingButton onClick={()=>{handlePackage(4)}}>Choose Plan</PricingButton>
        </PricingTable>
      </Grid>
    </Grid>
  </Container>
</PriceSection>


      <Container >
        <Box sx={{ backgroundColor: '#0d0c17', padding: '90px 40px 100px', borderRadius: '20px', color: 'white', textAlign: "center", marginTop: "100px",position:'relative' }}>
          <Container>
            <Typography variant="h3" fontWeight={700} gutterBottom>
              Upgrade Your Business with Our <br /> Cutting-Edge POS Solutions!
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }} color='#a3a2a3'>
              Experience the future of transactions! Schedule a demo today and witness how <br /> our POS solution can revolutionize your business.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2,flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center' }}>

              <StyledButton3 variant="contained" onClick={handleClick} sx={{minWidth:{xs:"200px"} }} >
                Start Free Trial
              </StyledButton3>

              <StyledButton1 variant="contained" onClick={handlePurchase} sx={{minWidth:{xs:"200px"} }}>
                Purchase Now
              </StyledButton1>

            </Box>
            <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
              <img src={EllipseSvg} alt="" />
            </Box>
            <Box sx={{ position: 'absolute', bottom: 0, left: 0 }}>
              <img src={StrokeSvg} alt="" />
            </Box>
          </Container>
        </Box>

      </Container>

    </>
  );
};

export default PricingPage;
