import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import ContactUsPage from './pages/ContactUsPage';
import FaqPage from './pages/FaqPage';
import FeaturesPage from './pages/FeaturesPage';
import HomePage from './pages/HomePage';
import PricingPage from './pages/PricingPage';

function App() {
  return (
 <>
 <Header />
 <HomePage />
 <FeaturesPage />
 
 <PricingPage />
 <FaqPage />
 <ContactUsPage />
 <Footer />
 </>
  );
}

export default App;
